import type { MetaFunction } from '@remix-run/node'
import { App } from '~/application/constants/App'
import jsonld from 'jsonld'

export const getTitle = (title: string) => {
  return title ? `${title} | ${App.name}` : App.name
}

export const getMeta = (data: {
  title: string
  url: string
  schema?: jsonld.NodeObject
}) => {
  const { schema } = data
  const description = `BeBloomingは個人の活動を幅広く知ってもらうための情報発信、企業からのスポンサー・サポートを募集することができます。`
  const meta: ReturnType<MetaFunction> = [
    { title: getTitle(data.title) },
    {
      name: 'description',
      content: description,
    },
    { name: 'og:title', content: getTitle(data.title) },
    { name: 'og:url', content: `${App.url}${data.url}` },
    { name: 'og:description', content: description },
    { name: 'og:image', content: `${App.url}/images/ogp.png` },
    { name: 'og:site_name', content: App.name },
  ]

  if (schema) {
    meta.push({
      'script:ld+json': schema,
    })
  }

  return meta
}
